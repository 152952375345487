/*=====================================================
16. shop-home-3
======================================================*/

/* 16.1 wpo-category-section-s2 */

.wpo-category-section-s2 {
    padding-bottom: 90px;

    @media(max-width:991px){
        padding-bottom: 60px;
    }
    @media(max-width:767px){
        padding-bottom: 50px;
    }

    .container {
        padding-top: 120px;
        border-top: 1px solid #D9D9D9;

        @media(max-width:991px) {
            padding-top: 90px;
        }

        @media(max-width:767px) {
            padding-top: 80px;
        }
    }

    .wpo-category-wrap {
        .wpo-category-item {
            padding: 20px;
            background: #FFF;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
            margin-bottom: 30px;

            .wpo-category-img {
                overflow: hidden;

                img {
                    -webkit-transition: all .3s;
                    transition: all .3s;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    width: 100%;
                }
            }

            &:hover {
                .wpo-category-img {
                    img {
                        -webkit-transform: scale(1.2) rotate(5deg);
                        transform: scale(1.2) rotate(5deg);

                    }
                }
            }

            .wpo-category-text {
                text-align: center;
                padding-top: 20px;

                a {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: $dark-gray;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }
        }
    }
}


/* 16.2 wpo-order-section */

.wpo-order-section {
    background: #F5F5F5;
    z-index: 1;
    overflow: hidden;
    position: relative;
    padding-bottom: 90px;

    @media(max-width:991px){
        padding-bottom: 60px;
    }
    @media(max-width:767px){
        padding-bottom: 50px;
    }

    .shape {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .wpo-order-wrap {
        max-width: 1620px;
        margin: 0 auto;

        .wpo-order-item {
            max-width: 375px;
            text-align: center;
            margin: 0 auto;
            margin-bottom: 30px;

            .wpo-order-img {
                padding: 20px;
                background: $white;
                border-radius: 50%;
                margin-bottom: 30px;

                img {
                    border-radius: 50%;
                }
            }

            .wpo-order-text {
                h2 {
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 20px;
                }
                p{
                    max-width: 318px;
                    margin: 0 auto;
                }
            }
        }

        .col {
            &:nth-child(even) {
                .wpo-order-item {
                    margin-top: 90px;

                    @media(max-width:1199px){
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}